.containerfit {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.hero_bg_bo {
    position: relative;
    z-index: 1;
}
.rotate {
    transition: transform 1s ease; /* You can adjust the transition duration and easing function */
  }
.header_sectio {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5; /* Adjust the z-index value as needed */
}

.fixed-navbar {
    position: fixed;
    align-self: center;
    top: 0;
    width: 100%;
    z-index: 50000; /* Adjust the z-index to make sure the navbar appears above other elements */
    background-color: #ffffff; /* Set your desired background color */
  }

.navbutton{
    color: bisque;
    width: 10px;
    height: 10px;
}