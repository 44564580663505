.scrolldown {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: right top 0;
  -ms-transform-origin: right top 0;
  transform-origin: right top 0;
  float: right;
}
.scrolldownO {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: right top 0;
  -ms-transform-origin: right top 0;
  transform-origin: right top 0;
  float: right;
}
.scrolldown i {
  padding-left: 50px;
}
.scrolldown a:hover,
.scrolldown a:focus,
.scrolldown a:active {
  color: #151515 !important;
}

html[data-useragent*="MSIE 10.0"] .scrolldown,
.oldie .scrolldown {
  display: none;
}

.scroll-icon {
  display: inline-block;
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  color: #ffffff !important;
  background: transparent;
  position: relative;
  top: 50px;
  right: 120px;
  -webkit-animation: animate-it 3s ease infinite;
  animation: animate-it 3s ease infinite;
}
.scroll-iconO {
  display: inline-block;
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  color: #ffffff !important;
  background: transparent;
  position: relative;
  bottom: -190px;
  left: -140px;

  -webkit-animation: animate-it 3s ease infinite;
  animation: animate-it 3s ease infinite;
}




/* vertical animation */
@-webkit-keyframes animate-it {
  0%,
  60%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-5px);
  }
  40% {
    -webkit-transform: translateX(20px);
  }
}
@keyframes animate-it {
  0%,
  60%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-5px);
  }
  40% {
    -webkit-transform: translateX(20px);
  }
}

@media only screen and (max-width: 768px) {
  .home-content-tablecell .row {
    max-width: 600px;
  }
  .home-content-tablecell h3 {
    font-size: 1.8rem;
  }
  .home-content-tablecell h1 {
    font-size: 5.2rem;
  }
  .home-content-tablecell h1 br {
    display: none;
  }

  .home-social-list {
    left: 36px;
    bottom: 30px;
    font-size: 2.1rem;
  }
  .home-social-list::before {
    height: 30px;
  }

  .scrolldown .scroll-icon {
    font-size: 1.2rem;
    top: 24px;
    right: 10px;
  }
}
/* .scroll-icon {
  display: inline-block;
  font-family: "montserrat-bold", sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  color: #ffffff !important;
  background: transparent;
  position: relative;
  top: 32x;
  right: 68px;
  -webkit-animation: animate-it 3s ease infinite;
  animation: animate-it 3s ease infinite;
} */

@media only screen and (max-width: 768px) {
  .home-content-tablecell .row {
    max-width: 600px;
  }
  .home-content-tablecell h3 {
    font-size: 1.8rem;
  }
  .home-content-tablecell h1 {
    font-size: 5.2rem;
  }
  .home-content-tablecell h1 br {
    display: none;
  }

  .home-social-list {
    left: 36px;
    bottom: 30px;
    font-size: 2.1rem;
  }
  .home-social-list::before {
    height: 30px;
  }

  .scrolldown .scroll-icon {
    font-size: 1.2rem;
    top: 24px;
    right: 10px;
  }
}
